import React from "react";
import {Container, Image, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class AppNav extends React.Component {
    render() {
        return (
            <div>
                <Navbar expand={"lg"} bg={"light"} variant={"light"} className={"p-1"}>
                    <Container className={"justify-content-center text-center"}>
                        <Navbar.Brand to={"/"} as={Link}>
                            <Image src={"logo.png"} width={"100"}/>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={"basic-navbar-nav"} />
                        <Navbar.Collapse id={"basic-navbar-nav"} className={"justify-content-end"}>
                            <Nav className={"me-auto"}>
                                <Nav.Link to={"/"} as={Link}>Home</Nav.Link>
                                <Nav.Link to={"/book"} as={Link}>Contact Us</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        );
    }
}