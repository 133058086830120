import ImageCarousel from "../ImageCarousel";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Home() {
    return (
        <div>
            <ImageCarousel/>
            <Container md className={"p-4 w-75"} >
                <Row>
                    <Col>
                        <h1>Welcome</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={"py-3"}>
                            Welcome to our new website. We are dedicated to providing reliable, and professional
                            property repairs, maintenance, and renovation services in Dorset
                            & Greater London areas. With over 20 years of experience we endeavour to ensure all the work
                            we carry out is to the highest standards possible. We pride ourselves on quoting for the job
                            that needs to be done, and not just quoting to get the job.
                        </div>
                        <div>
                            If you have a question or request, please <Link to={"/book"}><u>contact us</u></Link>
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md>
                        <h2>Maintain</h2>
                        <p>We provide several options for property maintenance, ranging from refurbishments to roofing</p>
                    </Col>
                    <Col md>
                        <h2>Repair</h2>
                        <p>We can repair anything, from the lights in your living room to the paint on your walls.</p>
                    </Col>
                    <Col md>
                        <h2>Renovate</h2>
                        <p>Our services include Plastering, Glazing, and Brickwork</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        Interested? <br/><br/>
                        <Button variant={"dark"} as={Link} to={"/book"}>Contact Us</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}