import React from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default class AppFooter extends React.Component {
    render() {
        return (
            <Container>
                <footer className={"pt-4 border-top"}>
                    <Row className={"justify-content-center"}>
                        <Col md className={"col-12 mb-4"}>
                            <Link to={"/"}>
                                <Image src={"logo.png"} width={"100"}/>
                            </Link>
                        </Col>
                        <Col md className={"col-6"}>
                            Not a duty, just a blessing
                        </Col>
                        <Col md className={"col-6"}>
                                07775 931 484<br />
                                info@mrhsolutions.co.uk<br />
                            Designed by <a href={"https://romanolucchesi.com"}>Romano Lucchesi</a>
                        </Col>
                    </Row>
                </footer>
            </Container>
        )
    }
}
