
import {Button, Container, Image, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function NoMatch() {
    return (
        <Container className={"p-4"}>
            <Row className={"mb-2"}>
                <Image src={"notfound.jpg"} fluid/>
            </Row>
            <Row className={"justify-content-center"}>
                <p>We can't find what you're looking for.</p>
            </Row>
            <Row className={"justify-content-center"}>
                <Button as={Link} to={"/"} className={"w-auto"}>Go Home</Button>
            </Row>
        </Container>
    )
}