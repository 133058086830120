import './App.css';
import {BrowserRouter} from "react-router-dom";
import React from "react";
import AppNav from "./components/AppNav";
import AppRouter from "./components/AppRouter";
import AppFooter from "./components/AppFooter";

export default class App extends React.Component {
    render() {
        return (
            <div className={"App"}>
                <BrowserRouter>
                    <AppNav/>
                    <AppRouter/>
                    <AppFooter/>
                </BrowserRouter>
            </div>
        );
    }
}