import React from "react";
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Book from "./pages/Book";
import NoMatch from "./pages/NoMatch";
import Success from "./pages/Success";

export default class AppRouter extends React.Component {
    render() {
        return (
            <Routes>
                <Route exact path={"/"} element={<Home/>}/>
                <Route exact path={"/book"} element={<Book/>}/>
                <Route exact path={"/success"} element={<Success/>}/>
                <Route exact path={"*"} element={<NoMatch/>}/>
            </Routes>
        );
    }
}