import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

export default function Success() {
    return (
        <div className={"my-3"}>
            <h1>Success</h1>
            <p>Thank you for your email. We will be in touch soon</p>
            <Button className={"mt-4"} as={Link} to={"/"}>Home</Button>
        </div>
    )
}